import Box from "@/components/Box";
import useBreakpoint from "@/hooks/useBreakpoint";

interface SectionDecorationProps {
  bgcolor: string;
}

const sectionRadiusStyle = {
  borderRadius: "80px 80px 0 0",
  height: "80px",
};

const mobileRadiusStyle = {
  borderRadius: "40px 40px 0 0",
  height: "40px",
};

const SectionDecoration: React.FC<SectionDecorationProps> = ({ bgcolor }) => {
  const isMobile = useBreakpoint("md");

  return (
    <Box
      sx={{
        bgcolor,
        ...sectionRadiusStyle,
        ...(isMobile && mobileRadiusStyle),
      }}
    ></Box>
  );
};

export default SectionDecoration;
