import React from "react";
import Text from "@/components/sections/Text";
import Box from "@/components/Box";
import Grid from "@/components/Grid";
import Link from "@/components/Link";
import { colors } from "@/styles/color";
import useBreakpoint from "@/hooks/useBreakpoint";
import ResponsiveImage from "@/components/ResponsiveImage";
import ResponsiveHeader from "@/components/layout/NavHeader/ResponsiveHeader";
import Container from "@/components/Container";
import Spacer from "@/components/Spacer";
import { useTranslation } from "react-i18next";
import SectionDecoration from "@/components/SectionDecoration";
import Button from "@/components/Button";
import InfoBox from "@/components/sections/InfoBox";
import PastProjectSection from "@/components/sections/PastProjectSection";
import StepsForSuccessSection from "@/components/sections/StepsForSuccessSection";
import Accordion from "@/components/CommonAccordion";

const FirstView = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useBreakpoint("md");
  const formUrl = t("firstview.formUrl");

  const createrHeaderContents = {
    links: [
      {
        label: "navBar.create",
        href: "",
        hasNoLink: true,
        isCurrentPage: true,
      },
      {
        label: "navBar.explor",
        href: "/discover",
      },
    ],
    hasLanguageSwitcher: true,
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: colors.lightGreen,
        }}
      >
        <Container sx={{ bgcolor: colors.lightGreen }}>
          <ResponsiveHeader headerContents={createrHeaderContents} />
        </Container>
        <Container sx={{ pl: isMobile ? 1 : 0, pr: isMobile ? 1 : 0 }}>
          <Container
            sx={{
              bgcolor: colors.white,
              pt: isMobile ? 0 : 3,
              pb: 4,
              pl: isMobile ? 1 : 0,
              pr: isMobile ? 1 : 0,
              borderRadius: 10,
            }}
          >
            <Box position="relative">
              <Box sx={{ pt: isMobile ? 40 : 8 }} />
              <ResponsiveImage
                mobileSrc="/assets/landing/mobile/img_firstview.png"
                desktopSrc="/assets/landing/desktop/img_firstview.png"
                alt="open town firstview image"
                desktopWidth={1144}
                desktopHeight={626}
                mobileWidth={346}
                mobileHeight={434}
                priority={true}
              />

              <Box
                position="absolute"
                top="1px"
                left="1px"
                right="1px"
                textAlign="center"
                sx={{ pl: isMobile ? 0 : 16, pr: isMobile ? 0 : 16 }}
              >
                {i18n.language === "en" ? (
                  <>
                    <Text
                      variant={isMobile ? "h2" : "h1"}
                      textAlign="center"
                      pt={2}
                      color={colors.primary}
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      {t("firstview.title.2")}
                    </Text>
                    <Text
                      variant={isMobile ? "h4" : "h3"}
                      fontWeight="bold"
                      textAlign="center"
                      mb={2}
                      pt={2}
                      color={colors.primary}
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      {t("firstview.title.1")}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      variant={isMobile ? "h4" : "h3"}
                      fontWeight="bold"
                      textAlign="center"
                      mb={2}
                      pt={isMobile ? 2 : 0}
                      color={colors.primary}
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      {t("firstview.title.1")}
                    </Text>
                    <Text
                      variant={isMobile ? "h2" : "h1"}
                      textAlign="center"
                      mb={2}
                      color={colors.primary}
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      {t("firstview.title.2")}
                    </Text>
                  </>
                )}

                <Box textAlign="center">
                  <Link
                    href={formUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        p: 2,
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("firstview.start")}
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Container>
        </Container>
        <Spacer />
      </Box>
    </>
  );
};
const NewCrowdfunding = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useBreakpoint("md");
  const currentLanguage = i18n.language;

  const desktopImageSrc =
    currentLanguage === "en"
      ? "/assets/landing/desktop/img_new_crowdfunding_en.png"
      : "/assets/landing/desktop/img_new_crowdfunding_ja.png";

  const mobileImageSrc =
    currentLanguage === "en"
      ? "/assets/landing/mobile/img_new_crowdfunding_en.png"
      : "/assets/landing/mobile/img_new_crowdfunding_ja.png";

  const infoBoxes = [
    {
      title: t("newCrowdfunding.nftSupport.title"),
      description: t("newCrowdfunding.nftSupport.description"),
    },
    {
      title: t("newCrowdfunding.communitySupport.title"),
      description: t("newCrowdfunding.communitySupport.description"),
    },
  ];

  return (
    <>
      <Box
        sx={{
          bgcolor: colors.lightGreen,
        }}
      >
        <SectionDecoration bgcolor={colors.white} />
      </Box>
      <Box sx={{ bgcolor: colors.white }}>
        <Container sx={{ bgcolor: colors.white }}>
          <Text
            variant={isMobile ? "h4" : "h3"}
            fontWeight="bold"
            textAlign="center"
            mb={2}
          >
            {t("newCrowdfunding.title.1")}
          </Text>
          <Text
            variant={isMobile ? "h3" : "h2"}
            fontWeight="bold"
            textAlign="center"
            mb={2}
          >
            {t("newCrowdfunding.title.2")}
          </Text>
          <Text variant="body1" textAlign="center" mb={2}>
            {t("newCrowdfunding.description")}
          </Text>
          <ResponsiveImage
            desktopSrc={desktopImageSrc}
            mobileSrc={mobileImageSrc}
            alt="new crowdfunding image"
            desktopWidth={1164}
            desktopHeight={624}
            mobileWidth={654}
            mobileHeight={833}
          />
          <Text
            variant={isMobile ? "h3" : "h2"}
            fontWeight="bold"
            textAlign="center"
            color={colors.primary}
            mt={4}
            mb={4}
          >
            {t("newCrowdfunding.support")}
          </Text>
          <Grid container spacing={4}>
            {infoBoxes.map((infoBox, index) => (
              <Grid item xs={isMobile ? 12 : 6} key={index}>
                <InfoBox
                  title={infoBox.title}
                  description={infoBox.description}
                />
              </Grid>
            ))}
          </Grid>
          <Spacer />
        </Container>
      </Box>
    </>
  );
};

const PastProjects = () => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint("md");

  const pastProjects = [
    // Karungu
    {
      headerText: t("pastProjects.karungu.copy"),
      ngoCases: t("pastProjects.karungu.ngoCases"),
      place: t("pastProjects.karungu.place"),
      desktopSrc: "/assets/landing/desktop/img_savannakidz.png",
      projects: [
        {
          title: t("pastProjects.karungu.project1.title"),
          desktopSrc: "/assets/landing/desktop/img_uganda_water.png",
          description: t("pastProjects.karungu.project1.description"),
        },
        {
          title: t("pastProjects.karungu.project2.title"),
          subTitle: t("pastProjects.karungu.project2.subTitle"),
          desktopSrc: "/assets/landing/desktop/img_uganda_school.png",
          description: t("pastProjects.karungu.project2.description"),
        },
      ],
      projectLink: t("pastProjects.karungu.project2.link"),
      projectLinkUrl: "https://www.savannakidz.com/",
    },
    // Yokoze
    {
      headerText: t("pastProjects.yokoze.copy"),
      ngoCases: t("pastProjects.yokoze.municipalCases"),
      place: t("pastProjects.yokoze.place"),
      desktopSrc: "/assets/landing/desktop/img_yokozecolorpass.png",
      projects: [
        {
          title: t("pastProjects.yokoze.project.title"),
          desktopSrc: "/assets/landing/desktop/img_yokoze_joylab.png",
          description: t("pastProjects.yokoze.project.description1"),
        },
        {
          title: t("pastProjects.yokoze.project.description2"),
          desktopSrc: "/assets/landing/desktop/img_yokoze_nft.png",
          description: t("pastProjects.yokoze.project.description3"),
        },
      ],
      projectLink: t("pastProjects.yokoze.project.link"),
      projectLinkUrl: "https://towns.open-town.org/yokoze/joylab/index.html",
    },
  ];

  return (
    <>
      <Box
        sx={{
          bgcolor: colors.white,
        }}
      >
        <SectionDecoration bgcolor={colors.lightGreen} />
      </Box>
      <Box
        sx={{
          bgcolor: colors.lightGreen,
        }}
      >
        <Container>
          <Text
            variant={isMobile ? "h3" : "h2"}
            fontWeight="bold"
            textAlign="center"
          >
            {t("pastProjects.title")}
          </Text>
          {pastProjects.map((project, i) => (
            <PastProjectSection key={i} pastProject={project} />
          ))}
        </Container>
        <Spacer />
      </Box>
    </>
  );
};

const ActionToApply = () => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint("md");
  const formUrl = t("firstview.formUrl");

  return (
    <>
      <Box
        sx={{
          bgcolor: colors.backgroundGrey,
        }}
      >
        <SectionDecoration bgcolor={colors.white} />
      </Box>
      <Box sx={{ bgcolor: colors.white }}>
        <Container>
          <Spacer />
          <Text
            variant={isMobile ? "h3" : "h2"}
            textAlign="center"
            mb={2}
            fontWeight="bold"
          >
            {t("cta.copy")}
          </Text>
          <Box textAlign="center">
            <Link href={formUrl} target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                size="large"
                sx={{
                  p: 2,
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {t("cta.title")}
              </Button>
            </Link>
          </Box>
          <Spacer />
        </Container>
      </Box>
    </>
  );
};
const Faq = () => {
  const { t } = useTranslation();

  const renderListDescription = () => {
    const descriptions = [];
    for (let i = 1; i <= 6; i++) {
      descriptions.push(
        <Text key={i} mb={1}>
          {t(`faq.question7.description.${i}`)}
        </Text>
      );
    }
    return descriptions;
  };

  const renderFaqItems = () => {
    const AccordionItems = [];
    for (let i = 1; i <= 9; i++) {
      const description =
        i === 7 ? renderListDescription() : t(`faq.question${i}.description`);
      AccordionItems.push(
        <Grid item xs={12} md={6} key={`faq-question${i}`}>
          <Accordion
            title={t(`faq.question${i}.title`)}
            description={description}
            index={i}
          />
        </Grid>
      );
    }
    return AccordionItems;
  };

  return (
    <Container>
      <Box
        sx={{
          bgcolor: colors.white,
        }}
      >
        <Spacer />

        <Text
          variant="h3"
          fontWeight="bold"
          textAlign="center"
          mb={2}
          color={colors.primary}
        >
          {t("faq.title")}
        </Text>
        <Grid container spacing={4}>
          {renderFaqItems()}
        </Grid>

        <Spacer />
      </Box>
    </Container>
  );
};
const LandingPage = () => {
  return (
    <Box
      sx={{
        bgcolor: colors.white,
      }}
    >
      <FirstView />
      <NewCrowdfunding />
      <PastProjects />
      <StepsForSuccessSection />
      <ActionToApply />
      <Faq />
    </Box>
  );
};

export default LandingPage;
