import Box from "@/components/Box";
import Text from "@/components/sections/Text";

interface NumberCircleProps {
  number: number;
}

const NumberCircle: React.FC<NumberCircleProps> = ({ number }) => {
  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        left: "50%",
        top: "0",
        borderRadius: "50%",
        backgroundColor: "primary.main",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Text variant="h3" sx={{ color: "white" }}>
        {number}
      </Text>
    </Box>
  );
};

export default NumberCircle;
