import Head from "next/head";
import Landing from "@/features/Landing";
import { ThemeProvider, createTheme, baseTheme } from "@/hooks/useTheme";
import { useTranslation } from "react-i18next";
import type { NextPageWithLayout } from "@/pages/_app";
import TokenLayout from "@/components/layout/TokenLayout";
import theme from "@/styles/theme";

import { ReactElement, useState, useEffect } from "react";

const Index: NextPageWithLayout<any> = () => {
  const { t } = useTranslation();

  const host = process.env.NEXT_PUBLIC_FRONTEND_API_HOST;

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <title>Open Town</title>
        <meta property="og:title" content="Open Town" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={host} />
        <meta property="og:image" content={`${host}/img_ogp_opentown.png`} />
        <meta property="og:description" content={t("ogDescription") || ""} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@OpenTownProject" />

        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="icon" href={`${host}/favicon.ico`} />
        <meta name="theme-color" content="#3988FF" />
        <meta name="description" content={t("ogDescription") || ""} />
      </Head>
      <Landing />
    </ThemeProvider>
  );
};

Index.getLayout = function getLayout(page: ReactElement) {
  return <TokenLayout>{page}</TokenLayout>;
};

export default Index;
