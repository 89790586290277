"use client"
import Box from "@/components/Box";
import useBreakpoint from "@/hooks/useBreakpoint";

const Spacer = () => {
  const isMobile = useBreakpoint("md");

  let size = 80;
  if (isMobile) size = 48;

  return <Box sx={{ height: size }} />;
};

export default Spacer;
