import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ['"Josefin Sans"', '"Noto Sans JP"', "sans-serif"].join(","),
    fontSize: 16,
    h1: { fontSize: "3rem", color: "#3988FF", fontWeight: 700 },
    h2: { fontSize: "2rem", color: "#333333", fontWeight: 700 },
    h3: { fontSize: "1.5rem", color: "#333333" },
    h4: { fontSize: "1.25rem", color: "#333333" },
    h5: { fontSize: "1.125rem", color: "#333333" },
    h6: { fontSize: "1rem", color: "#333333" },
    subtitle1: { fontSize: "1.125rem", color: "#333333" },
    body1: { fontSize: "1rem", color: "#333333" },
    body2: { fontSize: "0.875rem", color: "#333333" },
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#1976d2",
    },
  },
});

export default theme;
