import NavBar from "@/components/layout/NavHeader/NavBar";
import MobileMenu from "@/components/layout/NavHeader/MobileMenu";
import useBreakpoint from "@/hooks/useBreakpoint";

interface HeaderContents {
  reversedStyle?: boolean;
  links?: Array<{
    label: string;
    href: string;
    hasNoLink?: boolean;
    isExternal?: boolean;
    isCurrentPage?: boolean;
  }>;
  button1?: { label: string; href: string; isExtarnal?: boolean };
  hasLanguageSwitcher?: boolean;
}
interface HeaderProps {
  headerContents?: HeaderContents;
}

const Header = ({ headerContents }: HeaderProps) => {
  const isMobile = useBreakpoint("md");
  if (isMobile) return <MobileMenu headerContents={headerContents} />;

  return <NavBar headerContents={headerContents} />;
};
export default Header;
