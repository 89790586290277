import MuiButton from "@mui/material/Button";
import { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import useBreakpoint from "@/hooks/useBreakpoint";

type CustomButtonProps = ButtonProps & {
  testID?: string;
};

const Button = ({
  sx,
  onPress,
  loading,
  startIcon,
  disabled,
  radius = "8px",
  ...rest
}: {
  sx?: any;
  onPress?: () => void;
  loading?: boolean;
  startIcon?: any;
  disabled?: boolean;
  radius?: any;
} & CustomButtonProps) => {
  let leftIcon = startIcon;
  let disable = disabled;
  if (loading) {
    leftIcon = (
      <CircularProgress size={20} sx={{ color: "primary.contrastText" }} />
    );
    disable = true;
  }

  const isMobile = useBreakpoint("md");

  return (
    <MuiButton
      sx={{
        borderRadius: radius,
        minHeight: isMobile ? 48 : 64,
        minWidth: "300px",
        textTransform: "none",
        borderWidth: "2px",
        ...sx,
      }}
      onClick={onPress}
      startIcon={leftIcon}
      disabled={disable}
      {...rest}
    />
  );
};

export default Button;
