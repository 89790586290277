import Grid from "@/components/Grid";
import Typography from "@/components/Typography";
import Link from "@/components/Link";
import Header from "@/components/layout/Header";
import styles from "./NavHeader.module.css";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "@/components/LanguageSwitcher";
import SubButton from "@/components/SubButton";

interface HeaderContents {
  reversedStyle?: boolean;
  links?: Array<{
    label: string;
    href: string;
    hasNoLink?: boolean;
    isExternal?: boolean;
    isCurrentPage?: boolean;
  }>;
  button1?: { label: string; href: string; isExtarnal?: boolean };
  hasLanguageSwitcher?: boolean;
}
interface HeaderProps {
  headerContents?: HeaderContents;
}
const NavBar = ({ headerContents }: HeaderProps) => {
  const { t } = useTranslation();

  const { reversedStyle, links, button1, hasLanguageSwitcher } =
    headerContents || {};

  return (
    <Grid
      container
      maxWidth="lg"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={3}>
        <Header
          reversedStyle={reversedStyle}
          sx={{ backgroundColor: "transparent" }}
        />
      </Grid>
      <Grid item xs={7}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-evenly"
        >
          {links?.map((link) => (
            <Grid item key={link.label}>
              <Link
                href={link.href}
                rel="noopener noreferrer"
                target={link.isExternal ? "_blank" : "_self"}
              >
                <Typography
                  variant="h5"
                  fontWeight={link.hasNoLink ? "bold" : ""}
                  className={
                    reversedStyle ? styles.textLinkReversed : styles.textLink
                  }
                >
                  {t(link.label)}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        {button1 && (
          <Grid item>
            <Link href={button1.href} rel="noopener noreferrer" target="_blank">
              <SubButton
                inverted
                sx={{
                  minHeight: "32px",
                  minWidth: "64px",
                }}
              >
                {t(button1.label)}
              </SubButton>
            </Link>
          </Grid>
        )}
        {hasLanguageSwitcher && (
          <Grid item>
            <LanguageSwitcher />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default NavBar;
