import Text from "@/components/sections/Text";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@/components/Box";

interface AccordionItemProps {
  title: string;
  description: React.ReactNode;
  index: number;
  isList?: boolean;
}

const CommonAccordion: React.FC<AccordionItemProps> = ({
  title,
  description,
  index,
  isList = false,
}) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`faq-question${index}-content`}
        id={`faq-question${index}-header`}
      >
        <Text fontWeight="bold">{title}</Text>
      </AccordionSummary>
      <AccordionDetails>{description}</AccordionDetails>
    </Accordion>
  );
};

export default CommonAccordion;
