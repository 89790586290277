import React from "react";
import Text from "@/components/sections/Text";
import Box from "@/components/Box";
import Grid from "@/components/Grid";
import { colors } from "@/styles/color";
import Link from "@/components/Link";
import useBreakpoint from "@/hooks/useBreakpoint";

import ResponsiveImage from "@/components/ResponsiveImage";

import Container from "@/components/Container";
import Spacer from "@/components/Spacer";
import { useTranslation } from "react-i18next";
import SectionDecoration from "@/components/SectionDecoration";

import Button from "@/components/Button";
import NumberCircle from "@/components/NumberCircle";

const StepsForSuccessSection = () => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint("md");
  const guideDocumentUrl = t("stepsSuccess.createProject.documentUrl");
  const artDocumentUrl = t("stepsSuccess.createContents.step1.documentUrl");

  return (
    <>
      <Box
        sx={{
          bgcolor: colors.lightGreen,
        }}
      >
        <SectionDecoration bgcolor={colors.backgroundGrey} />
      </Box>
      <Box sx={{ bgcolor: colors.backgroundGrey }}>
        <Container>
          <Text
            variant={isMobile ? "h3" : "h2"}
            fontWeight="bold"
            textAlign="center"
            mb={2}
            color={colors.primary}
          >
            {t("stepsSuccess.title")}
          </Text>
          <Box
            sx={{
              position: "relative",
              bgcolor: colors.white,
              p: isMobile ? 2 : 4,
              mt: 4,
              borderRadius: 4,
            }}
          >
            <NumberCircle number={1} />
            <Text
              variant={isMobile ? "h4" : "h3"}
              fontWeight="bold"
              textAlign="center"
              mb={2}
              mt={2}
            >
              {t("stepsSuccess.createProject.title")}
            </Text>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={isMobile ? 12 : 6}>
                <ResponsiveImage
                  desktopSrc="/assets/landing/desktop/img_owner_step_1.png"
                  alt="owner_step_1"
                  desktopWidth={407}
                  desktopHeight={224}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6} mt={isMobile ? 4 : 0}>
                <Text variant="body1" textAlign="center" mb={2}>
                  {t("stepsSuccess.createProject.description")}
                </Text>
                <Text variant="body1" textAlign="center" mb={2}>
                  {t("stepsSuccess.createProject.detail")}
                </Text>
                <Box textAlign="center">
                  <Link
                    href={guideDocumentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        fontWeight: "bold",
                        borderColor: "primary.main",
                        borderStyle: "solid",
                      }}
                    >
                      {t("stepsSuccess.createProject.document")}
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              position: "relative",
              bgcolor: colors.white,
              mt: isMobile ? 4 : 8,
              p: isMobile ? 2 : 4,
              borderRadius: 4,
            }}
          >
            <NumberCircle number={2} />
            <Text
              variant={isMobile ? "h4" : "h3"}
              fontWeight="bold"
              textAlign="center"
              mb={2}
              mt={2}
            >
              {t("stepsSuccess.createContents.title")}
            </Text>
            <Grid container spacing={4}>
              <Grid item xs={isMobile ? 12 : 4}>
                <ResponsiveImage
                  desktopSrc="/assets/landing/desktop/img_owner_step_2.png"
                  alt="owner_step_2"
                  desktopWidth={407}
                  desktopHeight={224}
                />
                <Text
                  variant={isMobile ? "h6" : "h5"}
                  fontWeight="bold"
                  textAlign="center"
                  color={colors.primary}
                  mt={2}
                  mb={2}
                >
                  {t("stepsSuccess.createContents.step1.title")}
                </Text>
                <Text variant="body1" textAlign="center" mb={2}>
                  {t("stepsSuccess.createContents.step1.description")}
                </Text>
                <Text variant="body1" textAlign="center" mb={1}>
                  {t("stepsSuccess.createProject.detail")}
                </Text>

                <Box textAlign="center">
                  <Link
                    href={artDocumentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        fontWeight: "bold",
                        borderColor: "primary.main",
                        borderStyle: "solid",
                      }}
                    >
                      {t("stepsSuccess.createContents.step1.document")}
                    </Button>
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 4} mt={isMobile ? 4 : 0}>
                <ResponsiveImage
                  desktopSrc="/assets/landing/desktop/img_owner_step_3.png"
                  alt="owner_step_3"
                  desktopWidth={407}
                  desktopHeight={224}
                />
                <Text
                  variant={isMobile ? "h6" : "h5"}
                  fontWeight="bold"
                  textAlign="center"
                  color={colors.primary}
                  mt={2}
                  mb={2}
                >
                  {t("stepsSuccess.createContents.step2.title")}
                </Text>
                <Text variant="body1" textAlign="center" mb={2}>
                  {t("stepsSuccess.createContents.step2.description")}
                </Text>
              </Grid>
              <Grid item xs={isMobile ? 12 : 4} mt={isMobile ? 4 : 0}>
                <ResponsiveImage
                  desktopSrc="/assets/landing/desktop/img_owner_step_4.png"
                  alt="owner_step_4"
                  desktopWidth={407}
                  desktopHeight={224}
                />
                <Text
                  variant={isMobile ? "h6" : "h5"}
                  fontWeight="bold"
                  textAlign="center"
                  mt={2}
                  mb={2}
                  color={colors.primary}
                >
                  {t("stepsSuccess.createContents.step3.title")}
                </Text>
                <Text variant="body1" textAlign="center" mb={2}>
                  {t("stepsSuccess.createContents.step3.description")}
                </Text>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ bgcolor: colors.yellow, p: 1, mt: 4, borderRadius: 10 }}>
            <Text
              variant={isMobile ? "h4" : "h3"}
              fontWeight="bold"
              textAlign="center"
              color={colors.primary}
            >
              {t("stepsSuccess.createContents.startSale")}
            </Text>
          </Box>
          <Box
            sx={{
              position: "relative",
              bgcolor: colors.white,
              p: isMobile ? 2 : 4,
              mt: 4,
              borderRadius: 4,
            }}
          >
            <NumberCircle number={3} />
            <Text
              variant={isMobile ? "h4" : "h3"}
              fontWeight="bold"
              textAlign="center"
              mb={2}
              mt={2}
            >
              {t("stepsSuccess.createContents.startProject.title")}
            </Text>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={isMobile ? 12 : 6}>
                <ResponsiveImage
                  desktopSrc="/assets/landing/desktop/img_owner_step_5.png"
                  alt="owner_step_5"
                  desktopWidth={407}
                  desktopHeight={224}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6} mt={isMobile ? 4 : 0}>
                <Text variant="body1" textAlign="center" mb={2}>
                  {t("stepsSuccess.createContents.startProject.description")}
                </Text>
              </Grid>
            </Grid>
            <Box textAlign="center">
              <Text
                variant={isMobile ? "h4" : "h3"}
                fontWeight="bold"
                textAlign="center"
                mt={2}
                mb={2}
              >
                {t("stepsSuccess.createContents.startProject.conclusion")}
              </Text>
            </Box>
          </Box>
          <Spacer />
        </Container>
      </Box>
    </>
  );
};

export default StepsForSuccessSection;
