import Text from "@/components/sections/Text";
import Box from "@/components/Box";
import { colors } from "@/styles/color";
import useBreakpoint from "@/hooks/useBreakpoint";

interface InfoBoxProps {
  title: string;
  description: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ title, description }) => {
  const isMobile = useBreakpoint("md");
  return (
    <Box
      sx={{
        borderColor: "primary.main",
        borderWidth: 2,
        borderStyle: "solid",
        borderRadius: 5,
        p: 2,
        height: isMobile ? 220 : 200,
      }}
    >
      <Text
        variant={isMobile ? "h4" : "h3"}
        fontWeight="bold"
        textAlign="center"
        mb={2}
        color={colors.default}
      >
        {title}
      </Text>
      <Text variant="body1" textAlign="center" mb={2} color={colors.default}>
        {description}
      </Text>
    </Box>
  );
};

export default InfoBox;
