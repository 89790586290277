import Image from "next/image";
import Box from "@/components/Box";
import useBreakpoint from "@/hooks/useBreakpoint";

interface ResponsiveImageProps {
  desktopSrc: string;
  alt: string;
  desktopWidth: number;
  desktopHeight: number;
  mobileSrc?: string;
  mobileWidth?: number;
  mobileHeight?: number;
  priority?: boolean;
}

const ResponsiveImage = ({
  desktopSrc,
  alt,
  desktopWidth,
  desktopHeight,
  mobileSrc,
  mobileWidth,
  mobileHeight,
  priority = false,
}: ResponsiveImageProps) => {
  const isMobile = useBreakpoint("md");

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {isMobile && mobileSrc ? (
        <Image
          src={mobileSrc}
          alt={alt}
          width={mobileWidth}
          height={mobileHeight}
          sizes="100vw"
          style={{
            width: "100%",
            height: "auto",
          }}
          objectFit="cover"
          priority={priority}
        />
      ) : (
        <Image
          src={desktopSrc}
          alt={alt}
          width={desktopWidth}
          height={desktopHeight}
          sizes="100vw"
          style={{
            width: "100%",
            height: "auto",
          }}
          objectFit="cover"
          priority={priority}
        />
      )}
    </Box>
  );
};

export default ResponsiveImage;
