import React from "react";
import Text from "@/components/sections/Text";
import Box from "@/components/Box";
import Grid from "@/components/Grid";
import { colors } from "@/styles/color";
import Button from "@/components/Button";
import Link from "@/components/Link";
import useBreakpoint from "@/hooks/useBreakpoint";

import ResponsiveImage from "@/components/ResponsiveImage";

interface PastProjectSectionProps {
  pastProject: any;
}

const PastProjectSection = (prop: PastProjectSectionProps) => {
  const { pastProject } = prop;
  const projectDesktopWidth = 416;
  const projectDesktopHeight = 256;

  const isMobile = useBreakpoint("md");

  return (
    <div>
      <Grid container alignItems="center" mt={isMobile ? 4 : 8}>
        <Grid item xs={isMobile ? 12 : 8}>
          <Text variant={isMobile ? "h4" : "h3"} fontWeight="bold" mb={2}>
            {pastProject.headerText}
          </Text>
          <Text variant="body1" fontWeight="bold" mb={2}>
            {pastProject.ngoCases}
          </Text>
          <Text variant="body1" fontWeight="bold" mb={2}>
            {pastProject.place}
          </Text>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <ResponsiveImage
            desktopSrc={pastProject.desktopSrc}
            alt="project image"
            desktopWidth={projectDesktopWidth}
            desktopHeight={projectDesktopHeight}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          borderRadius: 5,
          bgcolor: colors.white,
          p: isMobile ? 2 : 4,
          mb: 8,
        }}
      >
        <Grid container spacing={4}>
          {pastProject.projects.map((project: any, index: number) => (
            <Grid item xs={isMobile ? 12 : 6} key={index}>
              <Text variant={isMobile ? "h4" : "h3"} fontWeight="bold" mb={2}>
                {project.title}
              </Text>

              <ResponsiveImage
                desktopSrc={project.desktopSrc}
                alt="project image"
                desktopWidth={projectDesktopWidth}
                desktopHeight={projectDesktopHeight}
              />
              {project.subTitle && (
                <Text
                  variant="body1"
                  fontWeight="bold"
                  mb={2}
                  color={colors.primary}
                >
                  {project.subTitle}
                </Text>
              )}
              <Grid>
                <Text variant="body1" mb={2}>
                  {project.description}
                </Text>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Box textAlign="center">
          <Link
            href={pastProject.projectLinkUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                p: 2,
                fontWeight: "bold",
              }}
            >
              {pastProject.projectLink}
            </Button>
          </Link>
        </Box>
      </Box>
    </div>
  );
};

export default PastProjectSection;
