"use client";
import { useState } from "react";
import Box from "@/components/Box";
import Grid from "@/components/Grid";
import Text from "@/components/sections/Text";
import Link from "@/components/Link";
import Drawer from "@/components/Drawer";
import IconButton from "@/components/IconButton";
import styles from "./NavHeader.module.css";
import { colors } from "@/styles/color";
import Header from "../Header";
import LanguageSwitcher from "@/components/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import SubButton from "@/components/SubButton";

interface HeaderContents {
  reversedStyle?: boolean;
  links?: Array<{
    label: string;
    href?: string;
    isExtarnal?: boolean;
    hasNoLink?: boolean;
  }>;
  button1?: { label: string; href: string; isExtarnal?: boolean };
  button2?: { label: string; href: string; isExtarnal?: boolean };
  hasLanguageSwitcher?: boolean;
}
interface HeaderProps {
  headerContents?: HeaderContents;
}

const MobileMenu = ({ headerContents }: HeaderProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { reversedStyle, links, hasLanguageSwitcher, button1, button2 } =
    headerContents || {};

  const toggle = () => setOpen(!open);
  const closeDrawer = () => setOpen(false);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Header
          reversedStyle={reversedStyle}
          sx={{ backgroundColor: "transparent" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            active
            name="menu"
            onPress={toggle}
            color={colors.default}
          />
        </Box>
      </Box>

      <Drawer
        data-testid="drawer"
        open={open}
        onClose={toggle}
        anchor="right"
        PaperProps={{
          sx: {
            width: "80%",
            bgcolor: colors.primaryLight,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pr: 2,
            pt: 1,
          }}
        >
          <IconButton
            active
            name="close"
            onPress={toggle}
            color={colors.default}
          />
        </Box>
        <Box
          sx={{
            display: "block",
            pt: 2,
          }}
        >
          {links?.map((link) =>
            link.hasNoLink ? null : (
              <Box
                key={link.label}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pt: 3,
                }}
              >
                <Link
                  href={link.href || ""}
                  rel="noopener noreferrer"
                  target={link.isExtarnal ? "_blank" : "_self"}
                  onClick={closeDrawer}
                >
                  <Text variant="h4" className={styles.textLink}>
                    {t(link.label)}
                  </Text>
                </Link>
              </Box>
            )
          )}
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 32,
            }}
          >
            {button1 && (
              <Grid item>
                <Box>
                  <Link
                    href={button1.href}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <SubButton
                      inverted
                      sx={{
                        minHeight: "32px",
                        minWidth: "64px",
                      }}
                    >
                      {t(button1.label)}
                    </SubButton>
                  </Link>
                </Box>
              </Grid>
            )}

            {button2 && (
              <Grid item>
                <Link
                  href={button2.href}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={closeDrawer}
                >
                  <SubButton
                    inverted
                    sx={{
                      minHeight: "32px",
                      minWidth: "64px",
                    }}
                  >
                    {t(button2.label)}
                  </SubButton>
                </Link>
              </Grid>
            )}

            {hasLanguageSwitcher && <LanguageSwitcher />}
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;