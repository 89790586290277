import Button from "@/components/Button";

const SubButton = ({
  children,
  onPress,
  sx,
  inverted,
  loading,
  disabled,
}: {
  children: React.ReactNode;
  onPress?: () => void;
  sx?: any;
  inverted?: boolean;
  testID?: string;
  loading?: boolean;
  disabled?: boolean;
}) => {
  return (
    <Button
      disableElevation
      size="small"
      variant={inverted ? "outlined" : "contained"}
      onPress={onPress}
      loading={loading}
      disabled={disabled}
      sx={{
        borderRadius: "50px",
        pl: 2,
        pr: 2,
        ...(inverted
          ? {
              backgroundColor: "primary.contrastText",
            }
          : {}),
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

export default SubButton;
